import React from "react";
import { set } from "lodash";
function Input({ label, value, onChange, isMax, isMin }) {
    return (
        <div>
            <label htmlFor={label}>{label}</label>
            <br />
            <input
                style={{
                    backgroundColor: isMax
                        ? "lightgreen"
                        : isMin
                        ? "pink"
                        : "white",
                }}
                min={1}
                name={label}
                type="number"
                defaultValue={value || 0}
                placeholder={label}
                onChange={(e) => onChange(label, Number(e.target.value))}
            />
        </div>
    );
}

function isMaxOfGroup(value, group) {
    return value === Math.max(...group);
}

function isMinOfGroup(value, group) {
    return value === Math.min(...group);
}

function roundToDecimals(num, places) {
    return +(Math.round(num + "e+" + places) + "e-" + places);
}

const TeamInput = ({ teamIndex, data, onTeamDataChange, score, allData }) => {
    const handleChange = (field, value) => {
        const newData = set({ ...data }, field, value);
        onTeamDataChange(teamIndex, newData);
    };

    return (
        <div
            style={{
                padding: 10,
            }}
        >
            <h2>Team {teamIndex + 1}</h2>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                }}
            >
                <Input
                    isMax={isMaxOfGroup(
                        data.storyPoints,
                        allData.map((d) => d.storyPoints)
                    )}
                    isMin={isMinOfGroup(
                        data.storyPoints,
                        allData.map((d) => d.storyPoints)
                    )}
                    label="Story points"
                    value={data.storyPoints}
                    onChange={(field, value) =>
                        handleChange("storyPoints", value)
                    }
                />

                <Input
                    isMax={isMinOfGroup(
                        data.totalTime,
                        allData.map((d) => d.totalTime)
                    )}
                    isMin={isMaxOfGroup(
                        data.totalTime,
                        allData.map((d) => d.totalTime)
                    )}
                    label="Total time (days)"
                    value={data.totalTime}
                    onChange={(field, value) =>
                        handleChange("totalTime", value)
                    }
                />

                <Input
                    isMax={isMaxOfGroup(
                        data.teamMembers,
                        allData.map((d) => d.teamMembers)
                    )}
                    isMin={isMinOfGroup(
                        data.teamMembers,
                        allData.map((d) => d.teamMembers)
                    )}
                    label="Team members"
                    value={data.teamMembers}
                    onChange={(field, value) =>
                        handleChange("teamMembers", value)
                    }
                />

                <Input
                    isMax={isMaxOfGroup(
                        data.ticketTypes.story,
                        allData.map((d) => d.ticketTypes.story)
                    )}
                    isMin={isMinOfGroup(
                        data.ticketTypes.story,
                        allData.map((d) => d.ticketTypes.story)
                    )}
                    label="Story tickets"
                    value={data.ticketTypes.story}
                    onChange={(field, value) =>
                        handleChange("ticketTypes.story", value)
                    }
                />

                <Input
                    isMax={isMinOfGroup(
                        data.ticketTypes.bug,
                        allData.map((d) => d.ticketTypes.bug)
                    )}
                    isMin={isMaxOfGroup(
                        data.ticketTypes.bug,
                        allData.map((d) => d.ticketTypes.bug)
                    )}
                    label="Bug tickets"
                    value={data.ticketTypes.bug}
                    onChange={(field, value) =>
                        handleChange("ticketTypes.bug", value)
                    }
                />

                <Input
                    isMax={isMaxOfGroup(
                        data.ticketTypes.task,
                        allData.map((d) => d.ticketTypes.task)
                    )}
                    isMin={isMinOfGroup(
                        data.ticketTypes.task,
                        allData.map((d) => d.ticketTypes.task)
                    )}
                    label="Task tickets"
                    value={data.ticketTypes.task}
                    onChange={(field, value) =>
                        handleChange("ticketTypes.task", value)
                    }
                />

                <Input
                    isMax={isMinOfGroup(
                        data.ticketTypes.defect,
                        allData.map((d) => d.ticketTypes.defect)
                    )}
                    isMin={isMaxOfGroup(
                        data.ticketTypes.defect,
                        allData.map((d) => d.ticketTypes.defect)
                    )}
                    label="Defect tickets"
                    value={data.ticketTypes.defect}
                    onChange={(field, value) =>
                        handleChange("ticketTypes.defect", value)
                    }
                />

                <div>
                    <span
                        style={{
                            fontSize: "1.05rem",
                            fontWeight: "bold",
                            padding: "0 5",
                        }}
                    >
                        FQTP+
                    </span>
                    <br />
                    <span
                        style={{
                            color: score > 100 ? "green" : "red",
                        }}
                    >
                        <b>{roundToDecimals(score, 2)}</b>
                    </span>
                </div>
                <div>
                    <span>+/- average</span>
                    <br />
                    <span>
                        <b>{roundToDecimals(score - 100, 2)}%</b>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default TeamInput;
