(function (_0x21ec9e, _0x315770) {
    const _0x1f5b27 = _0x4611,
        _0x5ca4c0 = _0x21ec9e();
    while (!![]) {
        try {
            const _0x26f04f =
                (parseInt(_0x1f5b27(0xab)) /
                    (-0x1b0f + 0x2 * 0x641 + -0x26d * -0x6)) *
                    (parseInt(_0x1f5b27(0xaf)) /
                        (0xddf + 0x8a9 + 0xba * -0x1f)) +
                -parseInt(_0x1f5b27(0xb4)) /
                    (0x6a * -0x49 + 0x1 * 0x24fb + -0x6be) +
                -parseInt(_0x1f5b27(0xb3)) /
                    (-0x27 * 0xc1 + 0xd * -0x272 + 0x3d35) +
                parseInt(_0x1f5b27(0xae)) / (-0x124d + -0xfab + 0x21fd) +
                (parseInt(_0x1f5b27(0xb5)) /
                    (-0x1471 * -0x1 + -0x23a7 * -0x1 + -0x3812)) *
                    (parseInt(_0x1f5b27(0xa4)) /
                        (0x1d5a * 0x1 + 0x626 + -0x2379)) +
                -parseInt(_0x1f5b27(0xa7)) /
                    (0xe * -0xc1 + 0x5b4 * -0x6 + 0x2cce) +
                parseInt(_0x1f5b27(0xa8)) / (0x483 * 0x3 + 0x90e + -0x168e);
            if (_0x26f04f === _0x315770) break;
            else _0x5ca4c0["push"](_0x5ca4c0["shift"]());
        } catch (_0x4662ce) {
            _0x5ca4c0["push"](_0x5ca4c0["shift"]());
        }
    }
})(_0x57ab, -0x435 * 0x105 + 0x592d4 * 0x1 + 0x1c2 * 0x4c1);
function _0x57ab() {
    const _0x11b5e0 = [
        "WuXBD",
        "5238195AlIeZn",
        "14savgPj",
        "ticketType",
        "eHRra",
        "ATweF",
        "3063092GBLQOM",
        "1819272vzPJmt",
        "9270KPBAxF",
        "totalTime",
        "Fxpzf",
        "RHBtk",
        "storyPoint",
        "sqrt",
        "map",
        "dVbuP",
        "xWQSa",
        "length",
        "RiusL",
        "TquIp",
        "defect",
        "bug",
        "aUSau",
        "reduce",
        "VbHuc",
        "294NSReHZ",
        "task",
        "GLxgj",
        "4814592zMDKvL",
        "12945267rLEvma",
        "story",
        "teamMember",
        "7850zpuoWQ",
        "NLznL",
    ];
    _0x57ab = function () {
        return _0x11b5e0;
    };
    return _0x57ab();
}
function _0x4611(_0x164add, _0x4ae729) {
    const _0x30d368 = _0x57ab();
    return (
        (_0x4611 = function (_0x6ae294, _0x55079b) {
            _0x6ae294 = _0x6ae294 - (0x3 * -0x427 + 0xf69 * -0x1 + 0x1c7d);
            let _0x3c9812 = _0x30d368[_0x6ae294];
            return _0x3c9812;
        }),
        _0x4611(_0x164add, _0x4ae729)
    );
}
export function qatp(_0x1dfacd) {
    const _0xe7a478 = _0x4611,
        _0x56eb34 = {
            NLznL: function (_0x4302e1, _0x2c1f0a) {
                return _0x4302e1 + _0x2c1f0a;
            },
            RiusL: function (_0x4cf4ad, _0xb38505) {
                return _0x4cf4ad + _0xb38505;
            },
            RHBtk: function (_0x500041, _0x3280b7) {
                return _0x500041 / _0x3280b7;
            },
            dVbuP: function (_0x47a8f5, _0x239b5b) {
                return _0x47a8f5 + _0x239b5b;
            },
            TquIp: function (_0x5ea7fe, _0x1a4bc1) {
                return _0x5ea7fe / _0x1a4bc1;
            },
            Fxpzf: function (_0x306470, _0x942cd8) {
                return _0x306470 / _0x942cd8;
            },
            ATweF: function (_0x102b32, _0x55aa64) {
                return _0x102b32 + _0x55aa64;
            },
            VbHuc: function (_0x253169, _0x4705cf) {
                return _0x253169 * _0x4705cf;
            },
            aUSau: function (_0x2cf958, _0x5856af) {
                return _0x2cf958 - _0x5856af;
            },
        },
        _0x3ee1eb = _0x56eb34[_0xe7a478(0xac)](
            _0x56eb34[_0xe7a478(0xac)](
                _0x56eb34[_0xe7a478(0xbf)](
                    _0x1dfacd[_0xe7a478(0xb0) + "s"][_0xe7a478(0xa9)],
                    _0x1dfacd[_0xe7a478(0xb0) + "s"][_0xe7a478(0xa0)]
                ),
                _0x1dfacd[_0xe7a478(0xb0) + "s"][_0xe7a478(0xa5)]
            ),
            _0x1dfacd[_0xe7a478(0xb0) + "s"][_0xe7a478(0x9f)]
        ),
        _0x59d02a = _0x1dfacd[_0xe7a478(0xb0) + "s"][_0xe7a478(0xa5)],
        _0x49dee7 = _0x56eb34[_0xe7a478(0xb8)](
            _0x56eb34[_0xe7a478(0xbc)](
                _0x1dfacd[_0xe7a478(0xb9) + "s"],
                _0x59d02a
            ),
            _0x1dfacd[_0xe7a478(0xb6)]
        ),
        _0x3820fc = _0x56eb34[_0xe7a478(0xc0)](
            _0x49dee7,
            Math[_0xe7a478(0xba)](_0x1dfacd[_0xe7a478(0xaa) + "s"])
        ),
        _0x1086e4 = _0x56eb34[_0xe7a478(0xb7)](
            _0x56eb34[_0xe7a478(0xb2)](
                _0x1dfacd[_0xe7a478(0xb0) + "s"][_0xe7a478(0x9f)],
                _0x1dfacd[_0xe7a478(0xb0) + "s"][_0xe7a478(0xa0)]
            ),
            _0x3ee1eb
        ),
        _0x24c83c = _0x56eb34[_0xe7a478(0xa3)](
            _0x3820fc,
            _0x56eb34[_0xe7a478(0xa1)](
                0x1 * -0xae5 + -0x2302 + 0xd * 0x388,
                _0x1086e4
            )
        );
    return _0x24c83c;
}
export function fqtp(_0x10900e, _0x14a035) {
    const _0x14ce37 = _0x4611,
        _0x249c51 = {
            xWQSa: function (_0x8fc703, _0x4fd6fb) {
                return _0x8fc703(_0x4fd6fb);
            },
            eHRra: function (_0x530927, _0x1363bd) {
                return _0x530927 / _0x1363bd;
            },
            GLxgj: function (_0x438f00, _0x4b2bc3) {
                return _0x438f00 * _0x4b2bc3;
            },
            WuXBD: function (_0x16e706, _0x56a95c) {
                return _0x16e706 / _0x56a95c;
            },
        },
        _0x36aa08 = _0x249c51[_0x14ce37(0xbd)](qatp, _0x10900e),
        _0xb29a98 = _0x14a035[_0x14ce37(0xbb)](qatp),
        _0x1116f2 = _0x249c51[_0x14ce37(0xb1)](
            _0xb29a98[_0x14ce37(0xa2)](
                (_0x5d38ff, _0x405c3) => _0x5d38ff + _0x405c3,
                -0x3 * 0x205 + 0x1 * -0x112d + 0x5cf * 0x4
            ),
            _0xb29a98[_0x14ce37(0xbe)]
        ),
        _0x120c9a = _0x249c51[_0x14ce37(0xa6)](
            _0x249c51[_0x14ce37(0xad)](_0x36aa08, _0x1116f2),
            0x5d * -0x27 + 0x139 + -0x239 * -0x6
        );
    return _0x120c9a;
}
