import React, { useState } from "react";
import "./App.css";
import TeamInput from "./TeamInput";
import { fqtp } from "./fqtp";

function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}

function generateTeamData() {
    return {
        storyPoints: randomNumber(30, 45),
        totalTime: randomNumber(10, 20),
        teamMembers: randomNumber(3, 8),
        ticketTypes: {
            story: randomNumber(30, 40),
            bug: randomNumber(5, 15),
            task: randomNumber(2, 10),
            defect: randomNumber(5, 15),
        },
    };
}

const faqs = [
    {
        question: "What is FQTP+?",
        answer: "FQTP+ is a metric that aims to help teams understand how their throughput compares to other teams. It takes into account team size, quality, and type of work being done (tickets, stories, etc.) to provide a holistic sense of throughput.",
    },
    {
        question: "why is there a + in the name?",
        answer: "+ signifies a normalized metric where 100 is considered average. So, a score above or below 100 indicates % throughput above or below the average. For example, a score of 80 would mean that a team is 20% below the average throughput. A score of 120 would mean that a team is 20% above the average throughput.",
    },
    {
        question:
            "what if my team counts points differently? what if we classify defects and bugs differently?",
        answer: "FQTP+ is meant to be flexible. Defects and bugs are currently equally counted against team throughput as part of the quality penalty. If teams assign point values to other ticket types aside from stories, those will be discarded in the calculation.",
    },
];

const App = () => {
    const [teamsData, setTeamsData] = useState(
        Array(3)
            .fill(null)
            .map(() => generateTeamData())
    );

    const handleTeamDataChange = (teamIndex, updatedData) => {
        console.log(updatedData);
        const newTeamsData = [...teamsData];
        newTeamsData[teamIndex] = updatedData;
        setTeamsData(newTeamsData);
    };

    return (
        <div>
            <h1>FQTP+ Playground</h1>
            <p>
                This is a playground for the FQTP+ metric. You can add and
                remove teams, and change the data for each team.
            </p>

            <p>
                FQTP+ aims to be intuitive to understand. You should be able to
                see the influence each component has on the overall score.
            </p>

            <p>Try some of the following things. What happens to the store?:</p>

            <ul>
                <li>Add a team with a high or low number of team members.</li>
                <li>
                    Increase or decrease the number of engineers for a team.
                </li>
                <li>Increase or decreate lead time for a team.</li>
                <li>Set the number of bugs to be high.</li>
                <li>Set the number of defects to be high.</li>
                <li>Add a team with a high number of tasks.</li>
                <li>
                    Set up a team with a high number of story points and a high
                    number of bugs/defects.
                </li>
            </ul>

            <p>
                Fields are highlighted in red if they are the lowest or worst
                value in a group, and green if they are the highest (or best)
                value in a group.
            </p>

            <button
                disabled={teamsData.length >= 8}
                onClick={() => {
                    const newTeamsData = [...teamsData];
                    newTeamsData.push(generateTeamData());
                    setTeamsData(newTeamsData);
                }}
            >
                add team
            </button>

            <button
                disabled={teamsData.length <= 2}
                onClick={() => {
                    if (teamsData.length > 2) {
                        const newTeamsData = [...teamsData];
                        newTeamsData.pop();
                        setTeamsData(newTeamsData);
                    }
                }}
            >
                remove team
            </button>

            {teamsData.map((teamData, index) => (
                <TeamInput
                    key={index}
                    teamIndex={index}
                    data={teamData}
                    allData={teamsData}
                    onChange={handleTeamDataChange}
                    score={fqtp(teamData, teamsData)}
                    onTeamDataChange={handleTeamDataChange}
                />
            ))}

            <hr />

            <h2>FAQ</h2>
            {faqs.map(({ question, answer }) => (
                <div>
                    <h3>{question}</h3>
                    <p>{answer}</p>
                </div>
            ))}
        </div>
    );
};

export default App;
